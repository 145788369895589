<template>
  <span v-bind:class="['badge badge-pill p-2', getBadgeClass]">
    <i v-bind:class="['mr-1', getBadgeIcon]"></i> {{ getText }}</span
  >
</template>
<script>
export default {
  name: "ProductBadge",
  props: {
    competitors: {
      default: () => [],
    },
    averagePrice: {
      default: 0,
    },
    productPrice: {
      default: 0,
    },
    productOrder: {
      default: 0,
    },
  },
  data() {
    return {
      badgeColor: {
        empty: "alert-info",
        expensive: "alert-danger",
        mostExpensive: "alert-danger",
        priceError: "alert-danger",
        cheap: "alert-success",
        cheapest: "alert-success",
        equal: "alert-secondary",
      },
      badgeIcon: {
        empty: "fas fa-info-circle",
        expensive: "fas fa-exclamation-triangle",
        priceError: "fas fa-exclamation-triangle",
        mostExpensive: "fas fa-exclamation-triangle",
        cheap: "fas fa-thumbs-up",
        cheapest: "fas fa-crown",
        equal: "fas fa-equals",
      },
    };
  },
  computed: {
    getPriceStatus() {
      let result;

      if (!this.competitorExists) {
        result = "empty";
      } else {
        if (this.productOrder == 1) {
          result = "cheapest";
        } else if (this.mostExpensive) {
          result = "mostExpensive";
        } else if (this.averagePrice > 0) {
          if (this.productPrice > this.averagePrice) {
            result = "expensive";
          } else if (this.productPrice < this.averagePrice) {
            result = "cheap";
          } else if (this.productPrice == this.averagePrice) {
            result = "equal";
          }
        } else {
          result = "priceError";
        }
      }

      return result;
    },
    mostExpensive() {
      return (
        this.averagePrice > 0 &&
        this.productOrder - 1 ==
          this.competitors.filter((comp) => comp.price > 0).length
      );
    },
    getBadgeClass() {
      const key = this.getPriceStatus;
      return this.badgeColor[key];
    },
    getBadgeIcon() {
      const key = this.getPriceStatus;
      return this.badgeIcon[key];
    },
    getText() {
      const key = this.getPriceStatus;
      return this.badgeText[key];
    },
    competitorExists() {
      return this.competitors.length;
    },
    badgeText() {
      return {
        empty: this.$t("noCompetitor"),
        mostExpensive: this.$t("mostExpensive"),
        expensive: this.$t("expensiveAverage"),
        cheap: this.$t("cheapAverage"),
        cheapest: this.$t("cheapest"),
        equal: this.$t("equal"),
        priceError: "Rakip Fiyatları Hatalı",
      };
    },
  },
};
</script>